(function (app) {
    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.ResetSelectDevice", {
            url: "/ResetSelectDevice",
            component: "resetSelectDevice",
            data: {
                pageTitle: "Select Alternate Device"
            }
        });
    }]);

    app.component("resetSelectDevice", {
        templateUrl: "/App/SelectDevice/select-device.html",
        controller: resetSelectDeviceController
    });

    resetSelectDeviceController.$inject = ["authenticationApi", "$q", "$state"];
    function resetSelectDeviceController(authenticationApi, $q, $state) {
        var $ctrl = this;

        $ctrl.ui = { sending: false };

        $ctrl.sendToDevice = function (number) {
            if (!number) return $q.when();
            number = number.replace(/[^\d]/g, "");

            return authenticationApi.sendPin({ PhoneNumber: number }).then(function (response) {
                $state.go("^.ResetMfaEntry", { phoneNumber: number, alternate: true });
            }).finally(function () { $ctrl.ui.sending = false; });
        };
    }
})(window.app);