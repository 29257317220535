(function (app) {
    app.provider("userApi", [function () {
        this.$get = ["authApiGateway", function (authApiGateway) {
            var service = {};

            service.updatePassword = function updatePassword(currentPassword, newPassword, confirmNewPassword) {
                return authApiGateway.post("/Users/Password", { CurrentPassword: currentPassword, NewPassword: newPassword, ConfirmNewPassword: confirmNewPassword });
            };

            return service;
        }];
    }]);
})(window.app);