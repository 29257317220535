(function (app) {
    app.provider("mfaDeviceApi", [function () {
        this.$get = ["authApiGateway", function (authApiGateway) {
            var service = {};

            service.updateSmsPrimary = function updateSmsPrimary(deviceId, isPrimary) {
                return authApiGateway.put("/MfaDevices/SmsPrimary", { SmsDeviceId: deviceId, IsPrimary: isPrimary });
            };

            service.getMfaDevices = function getMfaDevices() {
                return authApiGateway.get("/MfaDevices");
            };

            service.deleteSmsDevice = function deleteSmsDevice(smsDeviceId) {
                return authApiGateway.delete("/MfaDevices/" + smsDeviceId);
            };

            return service;
        }];
    }]);
})(window.app);