(function (app) {

    app.provider("authenticationApi", [function () {
        this.$get = ["authApiGateway", function (authApiGateway) {
            var service = {};

            service.clearSession = function clearSession() {
                return authApiGateway.post("/Authentication/ClearSession");
            };

            service.login = function login(username, password, clientId) {
                return authApiGateway.post("/Authentication/Login", { Username: username, Password: password, ClientId: clientId });
            };

            service.submitMfa = function submitMfa(token, rememberMe) {
                return authApiGateway.post("/Authentication/SmsMfaEntry", { Pin: token, RememberMe: rememberMe });
            };

            service.submitMfaForPasswordReset = function submitMfaForPasswordReset(token, rememberMe) {
                return authApiGateway.post("/Authentication/PasswordReset/SmsMfaEntry", { Pin: token, RememberMe: rememberMe });
            };

            service.setupNewMfaDevice = function setupNewMfaDevice(phoneNumber) {
                return authApiGateway.post("/Authentication/SmsMfaSetup", { PhoneNumber: phoneNumber });
            };

            service.verifyNewMfaDevice = function verifyNewMfaDevice(pin, rememberMe, isPrimary) {
                return authApiGateway.post("/Authentication/SmsMfaSetupVerification", { Pin: pin, RememberMe: rememberMe, IsPrimary: !!isPrimary });
            };

            service.signup = function signup(userRegistrationToken, username, password, confirmPassword, clientId) {
                var model = {
                    Username: username,
                    Password: password,
                    ConfirmPassword: confirmPassword,
                    ClientId: clientId
                };
                return authApiGateway.post("/Authentication/UserRegistration/Complete", model, { params: { registrationToken: userRegistrationToken } });
            };

            service.sendPasswordReset = function sendPasswordReset(username, returnUrl) {
                return authApiGateway.post("/Authentication/PasswordReset", { Username: username, ReturnUrl: returnUrl });
            };

            service.passwordResetVerify = function passwordResetVerify(token, password, confirmPassword) {
                return authApiGateway.post("/Authentication/PasswordReset/Complete", { PasswordResetToken: token, Password: password, ConfirmPassword: confirmPassword });
            };

            service.resendPin = function resendPin() {
                return authApiGateway.post("/Authentication/Pin/Resend");
            };

            service.sendPin = function sendPin(device) {
                return authApiGateway.post("Authentication/Pin/Send", device);
            };

            service.isAuthenticated = function isAuthenticated() {
                return authApiGateway.get("Authentication");
            };

            return service;
        }];
    }]);

})(window.app);