(function (app) {

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.MfaEntry", {
            url: "/MfaEntry?{phoneNumber}?{alternate:bool}&{resent:bool}",
            component: "mfaEntry",
            data: {
                pageTitle: "Verify Mobile Device"
            },
            resolve: {
                phoneNumber: parameterResolverProvider.resolve("phoneNumber"),
                alternate: parameterResolverProvider.resolve("alternate"),
                resent: parameterResolverProvider.resolve("resent")
            }
        });
    }]);

    app.component("mfaEntry", {
        templateUrl: "/App/Login/mfa-entry.html",
        controller: mfaEntryCtrl,
        bindings: {
            phoneNumber: "<",
            alternate: "<",
            resent: "<",
            returnState: "<",
            returnParams: "<",
            postLoginReturnUrl: "<",
            autoRedirect: "<"
        }
    });

    mfaEntryCtrl.$inject = ["authenticationApi", "$state", "$timeout"];
    function mfaEntryCtrl(authenticationApi, $state, $timeout) {
        var $ctrl = this;

        $ctrl.submitMfa = function submitMfa(token, rememberMe) {
            return authenticationApi.submitMfa(token, rememberMe).then(nextState).catch(function (error) {
                if (error.status === 401) {
                    $state.go("Root.Login");
                }
            });
        };

        $ctrl.resend = function resend() {
            return authenticationApi.resendPin().then(nextState);
        };

        function nextState(response) {
            if (response.Type === "login-error") {
                $state.go("^.Login", { invalid: true });
            }
            else if (response.Type === "login-success") {
                if ($ctrl.returnState) {
                    $state.go($ctrl.returnState, $ctrl.returnParams, { inherit: false });
                }
                else if ($ctrl.autoRedirect && $ctrl.postLoginReturnUrl) {
                    window.location.href = $ctrl.postLoginReturnUrl;
                }
                else {
                    $state.go("^.Success");
                }
            }
            else if (response.Type === "mfa-entry") {
                $state.go("^.MfaEntry", { resent: true }, { reload: true }); // reload because we are currently in this state and need the pin field to clear
            }
        }

        $ctrl.disableResend = true;
        $timeout(function () { $ctrl.disableResend = false; }, 10000);
    }

})(window.app);