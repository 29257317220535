(function (app, ng) {
    
    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Admin.EditClient.EditTheme", {
            url: "/Themes/{themeId}",
            component: "editTheme",
            resolve: {
                theme: ["$transition$", "themes", "$q", function ($transition$, themes, $q) {
                    var themeId = $transition$.params().themeId;
                    if (themeId === "new")
                        return { isNew: true };
                    return themes.find(function (t) { return t.ThemeId == themeId; }) || $q.reject();
                }]
            },
            protected: true,
        });
    }]);

    app.component("editTheme", {
        templateUrl: "/App/Admin/client-themes.html",
        controller: themeCtrl,
        bindings: {
            theme: "<",
            client: "<", 
            onAddTheme: "&"
        }
    });

    themeCtrl.$inject = ["$http", "$state", "loginRedirectService"];
    function themeCtrl($http, $state, loginRedirectService) {
        var $ctrl = this;
        $ctrl.save = function save() {
            var model = ng.extend({}, $ctrl.theme);
            var fd = new FormData();
            fd.append("model", ng.toJson(model));
            if ($ctrl.logo) fd.append("logo", $ctrl.logo.blob, $ctrl.logo.name);
            var options = {
                headers: { "Content-Type": undefined },
                transformRequest: ng.identity
            };
            if (!$ctrl.theme.isNew) return $http.put("/Clients/" + $ctrl.client.ClientId + "/Themes/" + $ctrl.theme.ThemeId, fd, options).catch(function (err) {
                if (err.status === 401 || err.status === 403) {
                    loginRedirectService.redirect();
                }
            });
            return $http.post("/Clients/" + $ctrl.client.ClientId + "/Themes", fd, options).then(function (response) {
                // onAddTheme, build obj and send back to parent list
                $state.go(".", { themeId: response.data });
            }).catch(function (err) {
                if (err.status === 401 || err.status === 403) {
                    loginRedirectService.redirect();
                }
            });
        };
    }

})(window.app, window.angular);