(function (app, ng) {

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.MfaSetup", {
            url: "/MfaSetup?{invalid:bool}&{isPrimary:bool}",
            component: "mfaSetup",
            resolve: {
                invalid: parameterResolverProvider.resolve("invalid"),
                isPrimary: parameterResolverProvider.resolve("isPrimary")
            },
            data: {
                pageTitle: "Add a Mobile Device"
            },
        });
    }]);

    app.component("mfaSetup", {
        templateUrl: "/App/Login/mfa-setup.html",
        controller: mfaSetupCtrl,
        bindings: {
            invalid: "<",
            isPrimary: "<"
        }
    });

    mfaSetupCtrl.$inject = ["authenticationApi", "$state", "loginRedirectService"];
    function mfaSetupCtrl(authenticationApi, $state, loginRedirectService) {
        var $ctrl = this;

        $ctrl.submitting = false;

        $ctrl.setupMfa = function setupMfa(phonenumber) {
            if ($ctrl.submitting) return;
            $ctrl.submitting = true;
            phonenumber = phonenumber.replace(/[^\d]/g, "");
            $ctrl.phoneNumber = phonenumber;
            return authenticationApi.setupNewMfaDevice(phonenumber, true).then(nextState).catch(function (error) {
                if (error.status === 401) {
                    loginRedirectService.redirect();
                }
            });
        };

        function nextState() {
            $state.go("^.MfaVerification", { phoneNumber: $ctrl.phoneNumber, isPrimary: $ctrl.isPrimary });
        }
    }

})(window.app, window.angular);