(function (app) {

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.ResetMfaEntry", {
            url: "/ResetMfaEntry?{phoneNumber}?{alternate:bool}&{resent:bool}",
            component: "resetMfaEntry",
            data: {
                pageTitle: "Verify Mobile Device"
            },
            resolve: {
                phoneNumber: parameterResolverProvider.resolve("phoneNumber"),
                alternate: parameterResolverProvider.resolve("alternate"),
                resent: parameterResolverProvider.resolve("resent")
            }
        });
    }]);

    app.component("resetMfaEntry", {
        templateUrl: "/App/Login/reset-mfa-entry.html",
        controller: resetMfaEntryCtrl,
        bindings: {
            phoneNumber: "<",
            alternate: "<",
            resent: "<",
            returnState: "<",
            returnParams: "<"
        }
    });

    resetMfaEntryCtrl.$inject = ["authenticationApi", "$state", "$timeout"];
    function resetMfaEntryCtrl(authenticationApi, $state, $timeout) {
        var $ctrl = this;

        $ctrl.submitMfa = function submitMfa(token, rememberMe) {
            return authenticationApi.submitMfaForPasswordReset(token, rememberMe).then(nextState);
        };

        $ctrl.resend = function resend() {
            return authenticationApi.resendPin().then(nextState);
        };

        function nextState(response) {
            if (response.Type === "login-error") {
                $state.go("^.ResetVerify", { invalid: true });
            }
            else if (response.Type === "login-success") {
                if ($ctrl.returnState) {
                    $state.go($ctrl.returnState, $ctrl.returnParams, { inherit: false });
                } else {
                    $state.go("^.Success");
                }
            }
            else if (response.Type === "mfa-entry") {
                $state.go("^.ResetMfaEntry", { resent: true }, { reload: true }); // reload because we are currently in this state and need the pin field to clear
            }
        }

        $ctrl.disableResend = true;
        $timeout(function () { $ctrl.disableResend = false; }, 10000);
    }

})(window.app);