(function (app, ng) {

    app.provider("figApiGatewayBuilder", [function () {
        this.$get = ["$http", "runtimeHttpInterceptors", "$injector", "$q", function ($http, runtimeHttpInterceptors, $injector, $q) {
            var key = "_apiGatewayKey";

            // ReSharper disable once InconsistentNaming
            function ApiGateway(baseUrl) {
                this.baseUrl = baseUrl;
                this.tag = {};
            }

            function tagConfig(tag, config) {
                var x = {};
                x[key] = tag;
                return ng.extend(x, config);
            }


            ApiGateway.prototype.get = function (relativeUrl, config) {
                config = tagConfig(this.tag, config);
                return $http.get(this.baseUrl + relativeUrl, config);
            };

            ApiGateway.prototype.getArray = function (relativeUrl, config) {
                config = tagConfig(this.tag, config);
                return $http.get(this.baseUrl + relativeUrl, config);
            };

            ApiGateway.prototype.getFile = function (relativeUrl, config) {
                config = tagConfig(this.tag, config);
                return $http.get(this.baseUrl + relativeUrl, ng.extend({ responseType: "blob" }, config));
            };

            ApiGateway.prototype.put = function (relativeUrl, data, config) {
                config = tagConfig(this.tag, config);
                return $http.put(this.baseUrl + relativeUrl, data, config);
            };

            ApiGateway.prototype.putFile = function (relativeUrl, data) {
                var config = tagConfig(this.tag, {
                    headers: { "Content-Type": undefined },
                    transformRequest: ng.identity
                });
                return $http.put(this.baseUrl + relativeUrl, data, config);
            };

            ApiGateway.prototype.post = function (relativeUrl, data, config) {
                config = tagConfig(this.tag, config);
                return $http.post(this.baseUrl + relativeUrl, data, config);
            };

            ApiGateway.prototype.postFile = function (relativeUrl, data) {
                var config = tagConfig(this.tag, {
                    headers: { "Content-Type": undefined },
                    transformRequest: ng.identity
                });
                return $http.post(this.baseUrl + relativeUrl, data, config);
            };

            ApiGateway.prototype.patch = function (relativeUrl, data, config) {
                config = tagConfig(this.tag, config);
                return $http.patch(this.baseUrl + relativeUrl, data, config);
            };

            ApiGateway.prototype.delete = function (relativeUrl, config) {
                config = tagConfig(this.tag, config);
                return $http.delete(this.baseUrl + relativeUrl, config);
            };

            ApiGateway.prototype.addInterceptor = function (interceptorFactory) {
                var tag = this.tag;
                var interceptor = $injector.invoke(interceptorFactory);
                return runtimeHttpInterceptors.addInterceptor([
                    function () {
                        return {
                            //only add methods if the interceptorFactory defined those methods
                            //within the methods that are defined, return the argument untouched unless the config key matches this instance
                            request: interceptor.request &&
                            function (config) {
                                if (config[key] !== tag) return config;
                                return interceptor.request(config);
                            },
                            requestError: interceptor.requestError &&
                            function (rejection) {
                                //handle cases where the config is sent back in the rejection as is or wrapped
                                if (rejection && rejection[key] === tag)
                                    return interceptor.requestError(rejection);
                                if (rejection && rejection.config && rejection.config[key] === tag)
                                    return interceptor.requestError(rejection);
                                return $q.reject(rejection);
                            },
                            response: interceptor.response &&
                            function (response) {
                                if (!response || !response.config || response.config[key] !== tag)
                                    return response;
                                return interceptor.response(response);
                            },
                            responseError: interceptor.responseError &&
                            function (rejection) {
                                if (!rejection || !rejection.config || rejection.config[key] !== tag)
                                    return $q.reject(rejection);
                                return interceptor.responseError(rejection);
                            }
                        };
                    }
                ]);
            };

            return {
                buildApiGateway: function (baseUrl) {
                    return new ApiGateway(baseUrl);
                }
            };
        }];
    }]);

    //////////////
    app.provider("runtimeHttpInterceptors", [function () {
        this.$get = ["$injector", function ($injector) {
            var interceptors = [];

            return {
                addInterceptor: addInterceptor,
                getInterceptors: getInterceptors
            };

            function getInterceptors() {
                //return a copy so the caller can't modify the array
                return interceptors.slice();
            }

            function addInterceptor(interceptorFactory) {
                var factory = $injector.invoke(interceptorFactory);
                interceptors.push(factory);
                //return de-register function
                return function () {
                    var index = interceptors.indexOf(factory);
                    if (index >= 0)
                        interceptors.splice(index, 1);
                };
            }
        }];
    }]);

    app.config(["$httpProvider", function ($httpProvider) {
        $httpProvider.interceptors.push([
            "runtimeHttpInterceptors", "$q", function (runtimeHttpInterceptors, $q) {
                //see https://github.com/angular/angular.js/blob/master/src/ng/http.js#L375
                return {
                    request: function (config) {
                        return runtimeHttpInterceptors.getInterceptors()
                            .filter(function (i) { return i.request; })
                            .reduce(
                            //chain promises
                            function (promise, i) { return promise.then(i.request); },
                            //initial value
                            $q.resolve(config)
                            );
                    },
                    requestError: function (rejection) {
                        return runtimeHttpInterceptors.getInterceptors()
                            .filter(function (i) { return i.requestError; })
                            .reduce(
                            //chain promises
                            function (promise, i) { return promise.catch(i.requestError); },
                            //initial value
                            $q.reject(rejection)
                            );
                    },
                    response: function (response) {
                        return runtimeHttpInterceptors.getInterceptors()
                            .reverse()
                            .filter(function (i) { return i.response; })
                            .reduce(
                            //chain promises
                            function (promise, i) { return promise.then(i.response); },
                            //initial value
                            $q.resolve(response)
                            );
                    },
                    responseError: function (rejection) {
                        return runtimeHttpInterceptors.getInterceptors()
                            .reverse()
                            .filter(function (i) { return i.responseError; })
                            .reduce(
                            //chain promises
                            function (promise, i) { return promise.catch(i.responseError); },
                            //initial value
                            $q.reject(rejection)
                            );
                    }
                };
            }
        ]);
    }]);
    //////////////
})(window.app, window.angular); // jshint ignore:line