(function (app, ng) {

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.Login", {
            url: "/Login?{invalid:bool}",
            component: "login",
            resolve: {
                invalid: parameterResolverProvider.resolve("invalid")
            },
            data: {
                pageTitle: "Sign In"
            }
        });
    }]);

    app.component("login", {
        templateUrl: "/App/Login/login.html",
        controller: loginCtrl,
        bindings: {
            invalid: "<",
            // bindings below here are resolved from parent state
            clientId: "<",
            postLoginReturnUrl: "<",
            registrationToken: "<", // optional, used in the html, when linked from SignUp (which needs registration token) so we can return back to that same state
            loginHint: "<",
            returnState: "<",
            returnParams: "<",
            autoRedirect: "<",
            hideFig: "<"
        }
    });

    loginCtrl.$inject = ["authenticationApi", "$state", "$timeout"];
    function loginCtrl(authenticationApi, $state, $timeout) {
        var $ctrl = this;


        $ctrl.login = function login(username, password) {
            $ctrl.isLoggingIn = true;
            return authenticationApi.login(username, password, $ctrl.clientId)
                .then(nextState)
                .finally(function () { $ctrl.isLoggingIn = false; });
        };

        function nextState(response) {
            if (response.Type === "mfa-entry") {
                $state.go("^.MfaEntry");
            }
            else if (response.Type === "mfa-setup") {
                $state.go("^.MfaSetup");
            }
            else if (response.Type === "login-success") {
                if ($ctrl.returnState) {
                    $state.go($ctrl.returnState, $ctrl.returnParams, { inherit: false }); //we do not want to inherit params
                } else if ((response.AutoRedirect || $ctrl.autoRedirect) && $ctrl.postLoginReturnUrl) {
                    window.location.href = $ctrl.postLoginReturnUrl;
                    $timeout(function () { $ctrl.isLoggingIn = true; }); // to keep the loading icon spinning while we redirect back
                } else {
                    $state.go("^.Success");
                }
            }
            else if (response.Type === "login-error") {
                $ctrl.invalid = true;
                $ctrl.lockedUntil = null;
            }
            else if (response.Type === "account-locked") {
                $ctrl.invalid = false;
                $ctrl.lockedUntil = moment().to(response.LockedUntil, true);
            }
        }
    }

})(window.app, window.angular);