(function (app, ng) {

    function resolveParam(param) {
        return ["$transition$", function ($transition$) { return $transition$.params()[param]; }];
    }

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Admin", {
            url: "/Admin?",
            abstract: true,
            component: "admin",
            redirectTo: "Admin.Clients"
        });
    }]);

    app.component("admin", {
        templateUrl: "/App/Admin/admin.html",
        controller: adminController,
        bindings: {}
    });

    function adminController() {
        document.getElementById("copyright").innerHTML = getThisYear();
        function getThisYear() {
            var date = new Date();
            return date.getFullYear();
        }
    }

})(window.app, window.angular);