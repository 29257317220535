(function (app, ng) {

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Admin.Clients", {
            url: "/Clients",
            component: "adminClients",
            resolve: {
                clients: ["clientApi", function (clientApi) {
                    return clientApi.getClients();
                }]
            },
            protected: true,
        });
    }]);

    app.component("adminClients", {
        templateUrl: "/App/Admin/clients.html",
        controller: adminClientsCtrl,
        bindings: {
            clients: "<"
        }
    });

    adminClientsCtrl.$inject = [];
    function adminClientsCtrl() {
        var $ctrl = this;
        //ctrl.addClient = ...
    }

})(window.app, window.angular);