(function (app, ng) {

    app.provider("authApiGateway", [function () {
        this.$get = ["figApiGatewayBuilder", function (figApiGatewayBuilder) {
            var api = figApiGatewayBuilder.buildApiGateway("");
            api.addInterceptor([function () {
                return {
                    response: function (response) {
                        if (response.status === 204) return response; //http no-content

                        //handle 202 accepted for command id etc

                        var obj = response.data;
                        if (!obj.Entry && !obj.Entries) return obj;

                        var ret = ng.extend(obj.Entry || obj.Entries, obj);
                        delete ret.Entries;
                        delete ret.Entry;
                        return ret;
                    }
                };
            }]);
            return api;
        }];
    }]);

})(window.app, window.angular); // jshint ignore:line