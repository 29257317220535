(function (app) {
    app.provider("userRegistrationApi", [function () {
        this.$get = ["authApiGateway", function (authApiGateway) {
            var service = {};

            service.getUserRegistration = function getUserRegistration(token) {
                return authApiGateway.get("/UserRegistration", { params: { registrationToken: token } });
            };

            return service;
        }];
    }]);
})(window.app);