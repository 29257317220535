(function (app, ng) {

    var EXPIRED = {};

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.SignUp", {
            url: "/SignUp",
            component: "signUp",
            data: {
                pageTitle: "Create a New Account"
            },
            resolve: {
                userRegistration: ["userRegistrationApi", "registrationToken", function (userRegistrationApi, registrationToken) {
                    // catch http 400/404 and return an empty object so the resolve does not fail
                    return userRegistrationApi.getUserRegistration(registrationToken).catch(function () { return EXPIRED; });
                }]
            },
            onEnter: ["userRegistration", "$transition$", function (userRegistration, $transition$) {
                if (userRegistration === EXPIRED)
                    return $transition$.router.stateService.target("Root.SignUpExpired", $transition$.params());
            }]
        });
    }]);

    app.component("signUp", {
        templateUrl: "/App/Login/signup.html",
        controller: signUpCtrl,
        bindings: {
            postLoginReturnUrl: "<",
            clientId: "<",
            userRegistration: "<",
            autoRedirect: "<"
        }
    });

    signUpCtrl.$inject = ["authenticationApi", "$state", "$timeout"];
    function signUpCtrl(authenticationApi, $state, $timeout) {
        var $ctrl = this;
        $ctrl.signup = function signup(username, password, confirmPassword) {
            $ctrl.isLoggingIn = true;
            return authenticationApi.signup($ctrl.userRegistration.Token, username, password, confirmPassword, $ctrl.clientId)
                .then(nextState, error)
                .finally(function () { $ctrl.isLoggingIn = false; });
        };

        function nextState(response) {
            if (response.Type === "mfa-setup") {
                $state.go("^.MfaSetup");
            }
            else if (response.Type === "login-success" && (response.AutoRedirect || $ctrl.autoRedirect)) {
                window.location.href = $ctrl.postLoginReturnUrl;
                $timeout(function () { $ctrl.isLoggingIn = true; }); // to keep the loading icon spinning while we redirect back
            }
        }

        function error(response) {
            if (response.data.Exists) {
                $ctrl.exists = true;
            }
            else if (response.data.Invalid) {
                $ctrl.errorText = response.data.Invalid[0];
            }
            else if (response.data.Username) {
                $ctrl.errorText = "This username is invalid. Username must start with a letter and can contain the special characters: . _ @";
                return;
               
            }
            else if (response.data.Password) {
                $ctrl.errorText = "The password must be 8 characters and contain 3 out of 4 of the following: uppercase letters, lowercase letters, numbers, and special characters.";
                return;
            }
            else if (response.data.Expired) {
                $state.go("^.SignUpExpired");
            }
        };
    }

    // from https://stackoverflow.com/questions/31671221/angular-ng-messages-how-to-check-password-confirmation
    app.directive("compareTo", [function () {
        return {
            require: ["ngModel"],
            scope: { compareTo: "=" },
            link: function (scope, elem, attr, ctrls) {
                var ngModel = ctrls[0];
                ngModel.$validators.compareTo = function (modelValue) {
                    return modelValue == scope.compareTo;
                };
                scope.$watch("compareTo", function () {
                    ngModel.$validate();
                });
            }
        };
    }]);

})(window.app, window.angular);