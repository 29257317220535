(function (app, ng) {

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.MfaVerification", {
            url: "/MfaVerification?{phoneNumber}&{isPrimary:bool}&{resent:bool}",
            component: "mfaVerification",
            data: {
                pageTitle: "Verify Mobile Device"
            },
            resolve: {
                phoneNumber: parameterResolverProvider.resolve("phoneNumber"),
                isPrimary: parameterResolverProvider.resolve("isPrimary"),
                resent: parameterResolverProvider.resolve("resent")
            }
        });
    }]);

    app.component("mfaVerification", {
        templateUrl: "/App/Login/mfa-verification.html",
        controller: mfaVerificationCtrl,
        bindings: {
            phoneNumber: "<",
            isPrimary: "<",
            resent: "<",
            returnState: "<",
            returnParams: "<",
            postLoginReturnUrl: "<",
            autoRedirect: "<"
        }
    });

    mfaVerificationCtrl.$inject = ["authenticationApi", "$state", "$timeout", "loginRedirectService"];
    function mfaVerificationCtrl(authenticationApi, $state, $timeout, loginRedirectService) {
        var $ctrl = this;

        $ctrl.verifyMfa = function verifyMfa(pin, rememberMe) {
            return authenticationApi.verifyNewMfaDevice(pin, rememberMe, $ctrl.isPrimary).then(nextState).catch(function (error) {
                if (error.status === 401) {
                    loginRedirectService.redirect();
                } else if ($ctrl.returnState) {
                    $state.go($ctrl.returnState, $ctrl.returnParams, { inherit: false });
                }
            });
        };

        $ctrl.resend = function resend() {
            return authenticationApi.resendPin().then(nextState);
        };

        function nextState(response) {
            if (response.Type === "mfa-error") {
                $state.go("^.MfaSetup", { invalid: true });
            }
            else if (response.Type === "mfa-success") {
                if ($ctrl.returnState) {
                    $state.go($ctrl.returnState, $ctrl.returnParams, { inherit: false });
                }
                else if ($ctrl.autoRedirect && $ctrl.postLoginReturnUrl) {
                    window.location.href = $ctrl.postLoginReturnUrl;
                }
                else {
                    $state.go("^.Success");
                }
            }
            else if (response.Type === "mfa-entry") {
                $state.go("^.MfaVerification", { resent: true }, { reload: true }); // reload because we are currently in this state and need the pin field to clear
            }
        }

        $ctrl.disableResend = true;
        $timeout(function () { $ctrl.disableResend = false; }, 10000);
    }

})(window.app, window.angular);