(function (app) {

    function resolveParam(paramName) {
        return ["$transition$", function ($transition$) {
            return $transition$.params()[paramName];
        }];
    }

    /*
     * this resolveParam function can be injected as a service or service provider into other services or app configuration runners, for example:
     * ["someService", "parameterResolver", function(someService, parameterResolver) { parameterResolver.resolve("id") }]
     * or
     * app.config(["someProvider", "parameterResolverProvider", function(someProvider, parameterResolverProvider) { parameterResolverProvider.resolve("id") }])
     */
    app.provider("parameterResolver", [function () {
        this.resolve = resolveParam;
        this.$get = [function () {
            return {
                resolve: resolveParam
            };
        }];
    }]);

})(window.app);