(function (app, ng) {

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Admin.EditClient", {
            url: "/Edit/{adminClientId}",
            component: "editClient",
            resolve: {
                client: ["$transition$", "clientApi", function ($transition$, clientApi) {
                    return clientApi.getClient($transition$.params().adminClientId);
                }],
                //redirectUris: ["$transition$", "clientApi", function ($transition$, clientApi) {
                //    return clientApi.getRedirectUris($transition$.params().adminClientId);
                //}],
                defaultTheme: ["$transition$", "clientApi", function ($transition$, clientApi) {
                    return clientApi.getDefaultTheme($transition$.params().adminClientId);
                }],
                themes: ["$transition$", "clientApi", function ($transition$, clientApi) {
                    return clientApi.getThemes($transition$.params().adminClientId);
                }]
            },
            protected: true,
        });
    }]);

    app.component("editClient", {
        templateUrl: "/App/Admin/client-edit.html",
        controller: editClientCtrl,
        bindings: {
            client: "<",
            // redirectUris: "<",
            defaultTheme: "<",
            themes: "<"
        }
    });

    app.directive("figFile", ["$q", function ($q) {
        return {
            scope: {
                preview: "=?preview"
            },
            require: ["ngModel"],
            link: function (scope, elem, attrs, ctrls) {
                var computePreview = scope.hasOwnProperty("preview");
                var ngModel = ctrls[0];
                elem.bind("change", function (ev) {
                    ngModel.$setViewValue({ blob: ev.target.files[0], name: ev.target.files[0].name });
                    if (!computePreview) return;
                    var def = $q.defer();
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        def.resolve(e.target.result);
                    };
                    reader.readAsDataURL(ev.target.files[0]);
                    def.promise.then(function (url) {
                        scope.$applyAsync(function () { scope.preview = url });
                    });
                });
            }
        };
    }]);

    editClientCtrl.$inject = ["clientApi", "loginRedirectService"];
    function editClientCtrl(clientApi, loginRedirectService) {
        var $ctrl = this;
        $ctrl.save = function save() {
            var model = ng.extend({}, $ctrl.client, $ctrl.defaultTheme); // , { RedirectUris: $ctrl.redirectUris });
            var fd = new FormData();
            fd.append("model", ng.toJson(model));
            if ($ctrl.logo) fd.append("logo", $ctrl.logo.blob, $ctrl.logo.name);
            return clientApi.updateClient($ctrl.client.ClientId, fd, {
                headers: { "Content-Type": undefined },
                transformRequest: ng.identity
            }).catch(function (err) {
                if (err.status === 401 || err.status === 403) {
                    loginRedirectService.redirect();
                }
            });
        };
    }

})(window.app, window.angular);