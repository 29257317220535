(function (app, ng) {

    app.provider("clientApi", [function () {
        this.$get = ["authApiGateway", function (authApiGateway) {
            var service = {};

            service.getBasicInfo = function getBasicInfo(clientId, theme) {
                return authApiGateway.get("/Clients/" + clientId + "/BasicInfo", { params: { theme: theme } });
            };

            service.getClients = function getClients() {
                return authApiGateway.get("/Clients");
            };

            service.getClient = function getClient(clientId) {
                return authApiGateway.get("/Clients/" + clientId);
            };

            service.getRedirectUris = function getRedirectUris(clientId) {
                return authApiGateway.get("/Clients/" + clientId + "/RedirectUris");
            };

            service.getDefaultTheme = function getDefaultTheme(clientId) {
                return authApiGateway.get("/Clients/" + clientId + "/Themes/Default");
            };

            service.getThemes = function getThemes(clientId) {
                return authApiGateway.get("/Clients/" + clientId + "/Themes");
            };

            service.updateClient = function updateClient(clientId, formData, config) {
                return authApiGateway.put("/Clients/" + clientId, formData, config);
            };

            return service;
        }];
    }]);

})(window.app, window.angular);