(function (app, ng) {

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.ResetExpired", {
            url: "/ResetExpired",
            component: "resetExpired",
            data: {
                pageTitle: "Password Reset Expired"
            }
        });
    }]);

    app.component("resetExpired", {
        templateUrl: "/App/Login/reset-expired.html"
    });

})(window.app, window.angular);