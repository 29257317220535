(function (app) {

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.SignUpExpired", {
            url: "/SignUpExpired",
            component: "signUpExpired",
            data: {
                pageTitle: "SignUp Token Expired"
            }
        });
    }]);

    app.component("signUpExpired", {
        templateUrl: "/App/Login/signup-expired.html"
    });

})(window.app);