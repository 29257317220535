(function (app, ng) {

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.Reset", {
            url: "/Reset",
            component: "reset",
            data: {
                pageTitle: "Request Password Reset",
            }
        });
    }]);

    app.component("reset", {
        templateUrl: "/App/Login/reset.html",
        controller: resetCtrl,
        bindings: {
            postLoginReturnUrl: "<"
        }
    });

    resetCtrl.$inject = ["authenticationApi", "$state"];
    function resetCtrl(authenticationApi, $state) {
        var $ctrl = this;
        $ctrl.reset = function reset(username) {
            $ctrl.isSubmitting = true;
            return authenticationApi.sendPasswordReset(username, $ctrl.postLoginReturnUrl)
                .then(function () {
                    return $state.go("^.ResetClose", { username: username });
                })
                .finally(function () { $ctrl.isSubmitting = false; });
        };
    }
    
})(window.app, window.angular);