(function (app) {
    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.Settings",
            {
                url: "/Settings",
                component: "accountSettings",
                protected: true,
            });
    }]);

    app.component("accountSettings", {
        templateUrl: "/App/Settings/account-settings.html", 
        controller: accountSettingsCtrl, 
        bindings: {
            postLoginReturnUrl: "<",
            pageTitle: "<"
        }
    });

    function accountSettingsCtrl() {
        var $ctrl = this;
    }

})(window.app);