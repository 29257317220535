(function (app, ng) {

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.ResetClose", {
            url: "/ResetClose?{username}",
            component: "resetClose",
            resolve: {
                username: parameterResolverProvider.resolve("username")
            },
            data: {
                pageTitle: "Password Reset Sent"
            }
        });
    }]);

    app.component("resetClose", {
        templateUrl: "/App/Login/reset-close.html",
        bindings: {
            username: "<"
        }
    });

})(window.app, window.angular);