(function (app) {

    app.run(["$transitions", "$window", function ($transitions, $window) {
        $transitions.onSuccess({}, function (trans) {
            var to = trans.to();
            var title = "";

            if (to.data && to.data.pageTitle) {
                title = to.data.pageTitle + "";
            }

            $window.document.title = title;
        });
    }]);

})(window.app);