(function (app) {

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.MfaSettings", {
            url: "/MfaSettings",
            component: "mfaSettings",
            data: {
                pageTitle: "My Devices",
            },
            resolve: {
                mfaDevices: ["mfaDeviceApi", function (mfaDeviceApi) {
                    return mfaDeviceApi.getMfaDevices();
                }],
                returnTitle: ["pageTitle", function (pageTitle) { return pageTitle; }]
            },
            protected: true,
        });
    }]);

    app.component("mfaSettings", {
        templateUrl: "/App/Settings/mfa-settings.html", 
        controller: mfaSettingsCtrl, 
        bindings: {
            mfaDevices: "<",
            postLoginReturnUrl: "<",
            returnTitle: "<"
        }
    });

    mfaSettingsCtrl.$inject = ["mfaDeviceApi", "$q", "$state", "loginRedirectService"];
    function mfaSettingsCtrl(mfaDeviceApi, $q, $state, loginRedirectService) {
        var $ctrl = this;

        $ctrl.deleteSmsDevice = function deleteSmsDevice(device) {
            device.deleting = true;
            mfaDeviceApi.deleteSmsDevice(device.SmsDeviceId)
                .then(function () {
                    var index = $ctrl.mfaDevices.SmsDevices.indexOf(device);
                    if (index > -1)
                        $ctrl.mfaDevices.SmsDevices.splice(index, 1);
                })
                .catch(function (err) {
                    if (err.status === 401) {
                        loginRedirectService.redirect();
                    }
                })
                .finally(function () {
                    device.deleting = false;
                });
        };

        $ctrl.setPrimarySmsDevice = function setPrimarySmsDevice(device) {
            $ctrl.updating = true;
            var promises = $ctrl.mfaDevices.SmsDevices
                .map(function (sms) {
                    return mfaDeviceApi.updateSmsPrimary(sms.SmsDeviceId, sms === device)
                        .then(function () { sms.IsPrimary = sms === device; })
                        .catch(function (err) {
                            if (err.status === 401) {
                                loginRedirectService.redirect();
                            }
                        });
                });
            return $q.all(promises).finally(function () { $ctrl.updating = false; });
        };

        $ctrl.redirectToMfaSetup = function redirectToMfaSetup() {
            return $state.go("^.MfaSetup", { isPrimary: false, returnState: $state.current.name, returnParams: Object.assign({}, $state.params) });
        };
    }

})(window.app);