(function (app) {
    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.SelectDevice", {
            url: "/SelectDevice",
            component: "selectDevice",
            data: {
                pageTitle: "Select Alternate Device"
            }
        });
    }]);

    app.component("selectDevice", {
        templateUrl: "/App/SelectDevice/select-device.html",
        controller: selectDeviceController
    });

    selectDeviceController.$inject = ["authenticationApi", "$q", "$state"];
    function selectDeviceController(authenticationApi, $q, $state) {
        var $ctrl = this;

        $ctrl.ui = { sending: false };

        $ctrl.sendToDevice = function (number) {
            if (!number) return $q.when();
            number = number.replace(/[^\d]/g,"");

            return authenticationApi.sendPin({ PhoneNumber: number }).then(function (response) {
                $state.go("^.MfaEntry", { phoneNumber: number, alternate: true });
            }).finally(function () { $ctrl.ui.sending = false; });
        };
    }
})(window.app);