(function (app) {

    app.run(["$transitions", "authenticationApi", function ($transitions, authenticationApi) {
        $transitions.onBefore({}, function (transition) {
            if (transition.to().protected) {
                return authenticationApi.isAuthenticated().then(function (response) {
                    if (!response) {
                        var currentParams = transition.params();
                        var redirectParams = {};
                        redirectParams.clientId = currentParams.clientId;
                        redirectParams.theme = currentParams.theme;
                        redirectParams.title = currentParams.title;
                        redirectParams.postLoginReturnUrl = currentParams.postLoginReturnUrl;
                        redirectParams.returnState = transition.to().name;
                        redirectParams.returnParams = currentParams;
                        return transition.router.stateService.target('Root.Login', redirectParams);
                    }
                })
            }
        })
    }]);

})(window.app);