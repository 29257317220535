(function (app, ng) {

    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.Success", {
            url: "/Success",
            component: "loginSuccess",
            data: {
                pageTitle: "Sign In Successful"
            },
            resolve: {
                returnTitle: ["pageTitle", function (pageTitle) { return pageTitle; }]
            }
        });
    }]);

    app.component("loginSuccess", {
        templateUrl: "/App/Login/success.html",
        controller: loginSuccessCtrl,
        bindings: {
            postLoginReturnUrl: "<",
            returnTitle: "<"
        }
    });

    function loginSuccessCtrl() { }

})(window.app, window.angular);