(function (app) {
    app.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("Root.PasswordUpdate",
            {
                url: "/PasswordUpdate",
                component: "passwordUpdate",
                protected: true,
            });
    }]);

    app.component("passwordUpdate", {
        templateUrl: "/App/Settings/password-update.html", 
        controller: updatePasswordCtrl
    });

    updatePasswordCtrl.$inject = ["userApi", "$timeout", "$state", "loginRedirectService"];
    function updatePasswordCtrl(userApi, $timeout, $state, loginRedirectService) {
        var $ctrl = this;

        $ctrl.updatePassword = function updatePassword(currentPassword, newPassword, confirmNewPassword) {
            $ctrl.error = null;
            userApi.updatePassword(currentPassword, newPassword, confirmNewPassword).then(function () {
                $ctrl.success = true;
                $timeout(function () {
                    $state.go("^.Settings");
                }, 750);
            }).catch(function (error) {
                $ctrl.error = error.data;
                if (error.status === 401) {
                    loginRedirectService.redirect();
                }
            }).finally(function () {
                $ctrl.updating = false;
            });
        };
    }

})(window.app);