(function (app, ng) {

    var EXPIRED = {};

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.ResetVerify", {
            url: "/ResetVerify?{invalid:bool}",
            component: "resetVerify",
            data: {
                pageTitle: "Reset Your Password",
            },
            resolve: {
                passwordReset: ["passwordResetApi", "$transition$", function (passwordResetApi, $transition$) {
                    // right now all errors just go to ResetExpired
                    // if we want to differentiate use a different value here and go to state based on that
                    var passwordResetToken = $transition$.params().passwordResetToken;
                    return passwordResetApi.getPasswordReset(passwordResetToken).catch(function () { return EXPIRED; });
                }],
                invalid: parameterResolverProvider.resolve("invalid")
            },
            onEnter: ["passwordReset", "$transition$", function (passwordReset, $transition$) {
                // onEnter necessary because ui router does not allow redirecting states from within resolves
                if (passwordReset === EXPIRED)
                    return $transition$.router.stateService.target("Root.ResetExpired", $transition$.params());
            }]
        });
    }]);

    app.component("resetVerify", {
        templateUrl: "/App/Login/reset-verify.html",
        controller: resetVerifyCtrl,
        bindings: {
            invalid: "<",
            postLoginReturnUrl: "<",
            passwordReset: "<",
            returnState: "<",
            returnParams: "<"
        }
    });

    resetVerifyCtrl.$inject = ["authenticationApi", "$state", "$timeout"];
    function resetVerifyCtrl(authenticationApi, $state, $timeout) {
        var $ctrl = this;
        $ctrl.UI = {};
        $ctrl.resetVerify = function resetVerify(password, confirmPassword) {
            $ctrl.UI.displayError = password !== confirmPassword || !$ctrl.checkPassword(password);

            //if (!email) {
            //    $ctrl.errorText = "Please enter the email address associated with your account.";
            //    return;
            //}

            if (password !== confirmPassword) {
                $ctrl.errorText = "The passwords entered do not match.";
                return;
            }

            if (!$ctrl.checkPassword(password)) {
                $ctrl.errorText = "The password must be 8 characters and contain 3 out of 4 of the following: uppercase letters, lowercase letters, numbers, and special characters.";
                return;
            }

            $ctrl.isSubmitting = true;
            return authenticationApi.passwordResetVerify($ctrl.passwordReset.Token, password, confirmPassword)
                .then(nextState)
                .catch(function (response) {
                    $ctrl.invalid = response.data || "An error has occurred";
                })
                .finally(function () { $ctrl.isSubmitting = false; });
        };

        function nextState(response) {
            if (response.Type === "mfa-entry") {
                $state.go("^.ResetMfaEntry");
            }
            else if (response.Type === "login-success") {
                if ($ctrl.returnState) {
                    $state.go($ctrl.returnState, $ctrl.returnParams, { inherit: false });
                }
                else if (response.AutoRedirect) {
                    window.location.href = $ctrl.postLoginReturnUrl;
                    $timeout(function () { $ctrl.isSubmitting = true; }); // to keep the loading icon spinning while we redirect back
                }
                else {
                    $state.go("^.Success");
                }
            }
        }
        $ctrl.checkPassword = function checkPassword(password) {
            return password
                .match(/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])).{8,}$/);
        }
    }

})(window.app, window.angular);