(function (app, ng) {

    app.config(["$stateProvider", "parameterResolverProvider", function ($stateProvider, parameterResolverProvider) {
        $stateProvider.state("Root.Error", {
            url: "/Error?{errorId}",
            component: "error",
            resolve: {
                errorId: parameterResolverProvider.resolve("errorId")
            },
            data: {
                pageTitle: "Error"
            }
        });
    }]);

    app.component("error", {
        templateUrl: "/App/Error/error.html",
        controller: errorCtrl,
        bindings: {
            errorId: "<"
        }
    });
    function errorCtrl() {
    }

})(window.app, window.angular);