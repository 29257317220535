(function (app) {
    app.provider("passwordResetApi", [function () {
        this.$get = ["authApiGateway", function (authApiGateway) {
            var service = {};

            service.getPasswordReset = function getPasswordReset(token) {
                return authApiGateway.get("/PasswordReset", { params: { passwordResetToken: token } });
            };

            return service;
        }];
    }]);
})(window.app);