(function (app) {
    app.provider("loginRedirectService", [function () {
        this.$get = ["$state", function ($state) {
            var service = {};

            service.redirect = function redirect() {
                var currentParams = Object.assign({}, $state.params);
                var redirectParams = {};
                redirectParams.clientId = currentParams.clientId;
                redirectParams.theme = currentParams.theme;
                redirectParams.title = currentParams.title;
                redirectParams.postLoginReturnUrl = currentParams.postLoginReturnUrl;
                redirectParams.returnState = $state.current.name;
                redirectParams.returnParams = currentParams;
                return $state.go("Root.Login", redirectParams);
            };

            return service;
        }];
    }]);
})(window.app);